<template>
  <q-card class="my-card text-left q-ma-md q-pb-sm">
    <q-list>
      <q-item>
        <q-item-section>
          <q-item-label class="text-weight-bold">{{
            $t("Profile.personal-address.my-address")
          }}</q-item-label>
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label class="" caption>
            {{ $t("Profile.personal-address.shipping-address") }}
          </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-icon
            class="cursor-pointer"
            color="primary"
            name="add_circle"
            @click="dialog = true; addressType='shipping'"
          />
        </q-item-section>
      </q-item>

      <div v-for="address in addresses">
        <q-item class="address-item q-ml-md" v-if="address.type=='shipping'">
            <q-item-section>
              <q-item-label>
                {{ formatAddress(address.address, address.postal_code, address.city, address.province, address.country) }}
              </q-item-label>
            </q-item-section>
              <q-icon
                class="address-icon q-mb-sm q-mr-xl q-ml-sm cursor-pointer"
                color="primary"
                size="xs"
                name="close"
                @click="addressToDeleteId=address.id; confirmDeletedialog=true"
              />
        </q-item>
      </div>

      <q-item v-if="nShippingAddresses===0" class="q-ml-md">
        <q-item-section>
          <q-item-label class="text-grey-7 text-overline text-weight-bold">{{
            $t("Profile.personal-address.please-introduce-address")
          }}</q-item-label>
        </q-item-section>
      </q-item>

      <q-item class="q-ml-md">
        <q-item-section>
          <q-item-label class="" caption>
            {{ $t("Profile.personal-address.billing-address") }}
          </q-item-label>
        </q-item-section>
        <q-item-section avatar>
          <q-icon
            class="cursor-pointer"
            color="primary"
            name="add_circle"
            @click="dialog = true; addressType='invoice'"
          />
        </q-item-section>
      </q-item>

      <div v-for="address in addresses">
        <q-item class="address-item q-ml-md" v-if="address.type=='invoice'">
            <q-item-section>
              <q-item-label>
                {{ formatAddress(address.address, address.postal_code, address.city, address.province, address.country) }}
              </q-item-label>
            </q-item-section>
            <q-icon
              class="address-icon q-mb-sm q-mr-xl q-ml-sm cursor-pointer"
              color="primary"
              size="xs"
              name="close"
              @click="addressToDeleteId=address.id; confirmDeletedialog=true"
            />
        </q-item>
      </div>
      
      <q-item v-if="nBillingAddresses===0" class="q-ml-md">
        <q-item-section>
          <q-item-label class="text-grey-7 text-overline text-weight-bold">{{
            $t("Profile.personal-address.please-introduce-address")
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-card>

  <AddPersonalAddress v-model="dialog" :addressType="addressType" />
  <WaitPopup v-model="waitDialog" :settings="settings" />
  <ConfirmDeleteDialog v-model="confirmDeletedialog" :settings="settings" @confirmDelete="startDeleteAddress()" />
</template>

<script>
import { defineAsyncComponent, ref } from "vue";
import { mapState, mapActions } from "vuex";
import api from "../../../../services/Api";
import { formatAddress } from "@/services/helpers/AddressFormat";

const AddPersonalAddress = defineAsyncComponent(() =>
  import("../editprofile/AddPersonalAddress.vue")
);

const WaitPopup = defineAsyncComponent(() =>
  import("../editprofile/dialogs/WaitEdit.vue")
);

const ConfirmDeleteDialog = defineAsyncComponent(() =>
  import("../editprofile/dialogs/ConfirmDelete.vue")
);

export default {
  components: {
    AddPersonalAddress,
    WaitPopup,
    ConfirmDeleteDialog
  },
  data() {
    return {
      dialog: false,
      addressType: ref("shipping"),
      waitDialog: false,
      confirmDeletedialog: false,
      addressToDeleteId: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),

    settings() {
      return this.$store.state.settings.settings;
    },

    addresses() {
      return this.$store.state.addresses.addresses.data;
    },

    nShippingAddresses() {
      return this.addresses.filter((address) => address.type === 'shipping').length;

    },

    nBillingAddresses() {
      return this.addresses.filter((address) => address.type === 'invoice').length;
    },
  },
  methods: {
    formatAddress,

    ...mapActions("settings", ["getSettings"]),

    ...mapActions("addresses", ["getAddresses"]),

    startDeleteAddress() {
      this.waitDialog = true;
      setTimeout(() => {
        this.deleteAddress();
      }, 3000);
    },

    deleteAddress() {
      api.delete("front-catalog/customers/addresses", {
          data: {
            address_id: this.addressToDeleteId
          }
        })
      .then(() => {
        this.getAddresses();
        this.waitDialog = false;
      })
    },

  },
  mounted() {
    this.getAddresses();
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}

/*.address-item .address-icon { display: none; }
.address-item:hover .address-icon { display: block; }*/
</style>
